<template>
  <div>
    <v-row>
      <v-col cols="3">
        <h3 class="mb-4">Рейсы в договоре</h3>
      </v-col>
      <v-spacer />

      <v-col cols="2">
        <v-menu
          ref="Дата закрытия"
          v-model="dateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          min-width="290px"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormated"
              class="mt-3"
              label="Дата закрытия"
              dense
              readonly
              hide-details
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>

          <v-date-picker
            v-model="date"
            no-title
            :show-current="true"
            :first-day-of-week="1"
            :weekday-format="dayOfWeekToFormat"
          >
            <v-spacer/>
            <v-btn
              text
              color="primary"
              @click="dateMenu = false"
            >
              Ок
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="2">
        <v-autocomplete
          v-model="currentRoute"
          :items="routes"
          :loading="loadingRefs"
          item-text="name"
          item-value="id"
          label="Маршрут *"
          @change="changeRoute"
          class="mt-0 pt-0"
          return-object
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="2">
        <v-autocomplete
          v-model="currentSheet"
          :items="filteredSheets"
          :loading="loadingRefs"
          :item-text="sheetText"
          item-value="id"
          label="Расписание"
          :disabled="currentRoute === null"
          class="mt-0 pt-0"
          return-object
          clearable
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col>
        <v-btn
          color="primary"
          @click="addGraphic"
          :disabled="currentRoute === null"
        >
          Добавить
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      height="400"
      loading-text="Загрузка рейсов"
      item-key="name"
      fixed-header
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.action="{ item }">
        <GraphicItem
          :item="item"
          @onRemove="removeGraphic"
        ></GraphicItem>
      </template>
    </v-data-table>

    <v-row dense>
      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="confirmAdd"
          :loading="loadingConfirm"
          :disabled="items.length === 0"
        >
          Применить
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import GraphicItem from "./GraphicItem";
  import GeneralServices from '@/services/GeneralServices';
  import {dayOfWeekToFormat} from "@/services/JetDate";

  export default {
    name: "Graphics",
    components: {
      GraphicItem,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      routes: {
        type: Array,
        required: true,
      },
      sheets: {
        type: Array,
        required: true,
      },
      loadingRefs: {
        type: Boolean,
        required: true,
      },
      loadingConfirm: {
        type: Boolean,
        required: true,
      },
    },
    data: () => ({
      headers: [
        {
          text: 'График',
          value: 'code',
        },
        {
          text: 'Маршрут',
          value: 'routeName',
        },
        {
          text: 'Начало действия',
          value: 'startDate',
        },
        {
          text: 'Окончание действия',
          value: 'endDate',
        },
        {
          text: 'Примечание',
          value: 'description',
        },
        {
          text: '',
          value: 'action',
          sortable: false,
        },
      ],
      currentRoute: null,
      currentSheet: null,
      applyRoute: null,
      date: new Date().toISOString().substr(0, 10),
      dateMenu: null,
      dayOfWeekToFormat: () => '',
    }),
    computed: {
      dateFormated () {
      return GeneralServices.formatDate(this.date);
      },
      filteredSheets() {
        if (this.currentRoute) {
          return this.sheets.filter(sheet => {
            if (sheet.routeId === this.currentRoute.id && sheet.type === 1) {
              this.currentSheet = sheet;
            }

            return sheet.routeId === this.currentRoute.id;
          });
        } else {
          return this.sheets;
        }
      },
    },
    created() {
      this.dayOfWeekToFormat = dayOfWeekToFormat;
    },
    methods: {
      sheetText(item) {
        return item.name + ' ' + item.type;
      },
      changeRoute() {
        this.currentSheet = null;
      },
      addGraphic() {
        this.$emit('onAdd');
      },
      removeGraphic(graphic) {
        this.$emit('onRemove', graphic);
      },
      confirmAdd() {
        this.$emit('onConfirm');
        this.$emit("date", this.date)
      }
    }
  }
</script>
