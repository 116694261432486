<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Закрыть рейсы в договоре № {{ item.vccarriercontractsDocnum }}
        </v-toolbar-title>
        <v-spacer/>

        <v-toolbar-items>
          <v-btn
            dark
            text
            @click="onClose"
          >
            Закрыть
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <div class="pa-4">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <Graphics
              :items="graphics"
              :loadingConfirm="loadingConfirm"
              :loadingRefs="loadingRefs"
              :routes="routes"
              :sheets="sheets"
              @onCancel="cancelAdd"
              @onConfirm="confirmAdd"
              @onAdd="showAllGraphics"
              @onRemove="removeGraphic"
              @date="getDate"
            ></Graphics>
          </v-tab-item>

          <v-tab-item>
            <AllGraphics
              :items="allGraphics"
              :loading="loadingAll"
              :loadingRefs="loadingRefs"
              :graphics="graphics"
              @onCancel="cancelAdd"
              @onAdd="onAdd"
            ></AllGraphics>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import Graphics from "./components/Graphics";
  import AllGraphics from "./components/AllGraphics";
  import GeneralServices from '@/services/GeneralServices';

  export default {
    name: "CloseSchedule",
    components: {
      AllGraphics,
      Graphics
    },
    props: {
      collection: {
        type: Object,
        required: true,
      }
    },
    data: () => ({
      dateWork: null,
      dialog: true,
      item: null,
      loadingConfirm: false,
      loadingAll: false,
      loadingRefs: false,
      tab: 0,
      graphics: [],
      allGraphics: [],
      routes: [],
      sheets: [],
      contracts: [],
      certificates: [],
      startContractDt: null,
      endContractDt: null,
      date: null,
      contractId: null,
    }),
    created() {
      this.item = this.collection.master.selected;

      if (!this.item) {
        this.dialog = false;
        jet.msg({
          text: "Необходимо выбрать запись!",
          color: "warning",
        });
        return;
      }

      this.startContractDt = this.item.vccarriercontractsStartcontractdt;
      this.endContractDt = this.item.vccarriercontractsEndcontractdt;
      this.contractId = this.item.vccarriercontractsId;
      this.dateWork = $utils.formatDate(
        new Date(Date.now()),
        'YYYY-MM-DD HH:mm:ss',
      );

      this.loadingRefs = true;
      //Получение списка маршрутов, расписаний и графиков
      this.getLists();
      this.loadingRefs = false;
    },
    methods: {
      getDate(date) {
        this.date = date;
      },
      onClose() {
        this.dialog = !this.dialog;
      },
      cancelAdd() {
        this.tab = 0;
      },
      showAllGraphics() {
        this.tab = 1;
      },
      onAdd(items) {
        this.tab = 0;
        items.forEach(item => {
          const index = this.graphics.findIndex(graphic => {
            return graphic.id === item.id;
          });

          if (index === -1) {
            this.graphics.push(item);
          }
        });
      },
      removeGraphic(item) {
        this.graphics = this.graphics.filter(graphic => graphic.id !== item.id)
      },
      confirmAdd() {
        this.loadingConfirm = true;
        //console.log(this.graphics);
        this.closeSchedule(this.graphics);
      },
      async getRoutesByContracts(carrierContractId, dateWork) {
        const requestContracts = {
          type: 'core-read',
          query: `sin2:/v:ffd7d02e-81e4-48bf-8066-c384c9385a84?filter=and(isnull(field(".vehicleId")),
                    eq(field(".contractId"),param("${carrierContractId}","id")),
                    lte(field(".routeId.route.verStart"),param("${dateWork}","date")),or(isnull(field(".routeId.route.verEnd")),
                    gte(field(".routeId.route.verEnd"),param("${dateWork}","date"))),
                    not(eq(field(".routeId.route.verStatus.Name"),param("Проект","string"))),or(isnull(field(".scheduleId")),
                    and(lte(field(".scheduleId.versionId.verStart"),param("${dateWork}","date")),
                    or(isnull(field(".scheduleId.versionId.verEnd")),
                    gte(field(".scheduleId.versionId.verEnd"),param("${dateWork}","date"))),
                    not(eq(field(".scheduleId.versionId.verStatus.Name"),param("Проект","string"))))))`
        }

        try {
          const resp = await jet.http.post(requestContracts);
          if (!resp.error) {
            return resp.result;
          }
        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список маршрутов',
            color: 'warning'
          });
          console.error('CloseShedule: getRoutesByContracts()',e);
          return;
        }
      },
      async getLists() {
        this.loadingRefs = true;

        try {
          const routesByContracts = await this.getRoutesByContracts(this.contractId, this.dateWork);
          let routes = [];
          let sheets = [];
          let releaseScheduleId = '';
          let tripCode = '';
          let routeName = '';
          let routeId = '';
          let note = '';
          let version = '';
          let verstatus = '';
          let scheduleId = '';
          let graphics = [];

          const data = (routesByContracts && routesByContracts.data) || [];
          await Promise.all(data.map(async it => {
            releaseScheduleId = it[10];
            tripCode = it[12];
            routeName = it[3] + ' ' + it[4];
            routeId = it[19];
            note = it[15];
            scheduleId = it[20];
            verstatus = it[11];
            version = it[10];

          routes.push({
            id: routeId,
            name: routeName,
          });

          if(!!releaseScheduleId){
            sheets.push({
              id: releaseScheduleId,
              name: version,
              type: verstatus,
              routeId: routeId,
            });
          }

         if(!!scheduleId){
           graphics.push({
            id: scheduleId,
            startDate: GeneralServices.formatDate(this.startContractDt),
            endDate: GeneralServices.formatDate(this.endContractDt),
            contractId: this.contractId,
            routeId: routeId,
            releaseScheduleId: releaseScheduleId,
            description: null,
            code: tripCode,
            routeName: routeName,
          });
         }

        }));

        this.routes = routes;
        this.sheets = sheets;
        this.allGraphics = $utils.uniqBy(graphics, graphic => graphic.id);

        } catch (e) {
          jet.msg({
            text: 'Не удалось загрузить список графиков',
            color: 'warning'
          });
          console.error('CloseShedule: getLists()',e);
          return;
        }
        this.loadingRefs = false;
      },
      async closeSchedule(paramsArray) {
        let params = [];
        let id = "";
        let schedules = [];

        const data = paramsArray || [];
        await Promise.all(
          data.map(async (it) => {
            id = it.id;
            schedules.push(id);
          })
        );

        if (this.startContractDt > this.date) {
          jet.msg({
            text:"Дата закрытия меньше даты начала действия договора по маршруту.",
            color: "warning",
          });
          this.loadingConfirm = false;
          return;
        }

        const optionsQuery = {
          type: "query",
          query:"d16cb8ba-8ffd-4753-89bc-5c7499bfa500.closeschedulesintocontract",
          params: {
            schedules: schedules.toString(),
            contractid: this.contractId,
            tenantid: this.item._sec_attrsTenantid,
            userid: this.item._sec_attrsUserid,
            startdt: this.startContractDt,
            indate: this.date,
          },
        };

        try {
          let resp = await jet.http.post(optionsQuery);
          if (resp.error) {
            throw resp.error;
          }
          jet.msg({
            text: `Закрытие рейсов в договоре № ${this.item.vccarriercontractsDocnum} выполнено успешно!`,
            type: 'warning'
          });
        } catch (e) {
          jet.msg({
            text: "Не удалось закрыть маршрут",
            color: "warning",
          });
          console.error("CloseSchedule: closeSchedule()", e);
          return;
        }
        this.dialog = false;
        this.collection.refresh();
      }
    }
  }
</script>
