<template>
  <div>
    <v-row>
      <v-col cols="8">
        <h3 class="mb-4">Добавить рейсы для закрытия</h3>
      </v-col>

      <v-col cols="4">
        <v-text-field
          v-model="searchText"
          label="Поиск графика"
          placeholder="Введите код графика"
          class="pt-0 mt-0"
          clearable
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="filteredGraphics"
      :loading="loading"
      no-data-text="Нет записей"
      height="400"
      loading-text="Загрузка графиков"
      class="mb-4"
      fixed-header
      show-select
      disable-pagination
      hide-default-footer
    ></v-data-table>

    <v-row dense>
      <v-col>
        <v-btn
          outlined
          color="red"
          @click="cancelAdd"
        >
          Отмена
        </v-btn>
      </v-col>

      <v-col class="text-right">
        <v-btn
          color="primary"
          @click="addGraphic"
          :disabled="selected.length === 0"
        >
          Выбрать
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'Graphics',
  props: {
    items: {
      type: Array,
      required: true,
    },
    graphics: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    selected: [],
    searchText: '',
    headers: [
      {
        text: 'Код графика',
        value: 'code',
      },
    ],
  }),
  computed: {
    filteredGraphics() {
      const re = new RegExp(this.searchText, 'i');

      // Показываем только те графики, которые еще не добавлены в договор
      return this.items.filter(graphic => {
        let index = this.graphics.findIndex(item => {
          return item.id === graphic.id;
        });

        if (this.searchText && !re.test(graphic.code)) {
          return false;
        }

        return index === -1;
      });
    },
  },
  methods: {
    // Показываем подтверждающий диалог
    addGraphic() {
      this.$emit('onAdd', this.selected);

      this.selected = [];
    },
    cancelAdd() {
      this.selected = [];

      this.$emit('onCancel');
    },
  },
};
</script>
